// regions
export const PRODUCTION = 'production'
export const DEVELOPMENT = 'development'
export const STAGING = 'staging'
export const RELEASE_STAGE = import.meta.env.VITE_RELEASE_STAGE || import.meta.env.NODE_ENV || PRODUCTION
export const IS_DEVELOPMENT = RELEASE_STAGE === DEVELOPMENT
export const IS_PRODUCTION = RELEASE_STAGE === PRODUCTION

// company info
export const COMPANY_NAME = 'Maverix Broadband'
export const COMPANY_PHONE = '+1-720-740-0888'

export const BASE_URL = import.meta.env.VITE_BASE_URL || 'https://maverixbroadband.com'

// mx api info
const apiUrls = {
	[PRODUCTION]: 'https://api.maverixbroadband.com',
	[STAGING]: 'https://staging-mx-back.maverixbroadband.com',
	[DEVELOPMENT]: 'https://dev-mx-back.maverixbroadband.com'
}

const portalUrls = {
	[PRODUCTION]: 'https://portal.maverixbroadband.com',
	[STAGING]: 'https://staging-mx-portal.maverixbroadband.com',
	[DEVELOPMENT]: 'https://dev-mx-portal.maverixbroadband.com'
}

const supportEmails = {
	[PRODUCTION]: 'support@maverixbroadband.com',
	[STAGING]: 'developers@maverixbroadband.com',
	[DEVELOPMENT]: 'developers@maverixbroadband.com'
}

const supportInternalEmails = {
	[PRODUCTION]: 'support-internal@maverixbroadband.com',
	[STAGING]: 'developers+internal@maverixbroadband.com',
	[DEVELOPMENT]: 'developers+internal@maverixbroadband.com'
}

const mapboxSatelliteStyleUrls = {
	[PRODUCTION]: 'mapbox://styles/viathdevs/clz0eh5sl01rr01qo6vi0emnk',
	[STAGING]: 'mapbox://styles/viathdevs/clz0eh5sl01rr01qo6vi0emnk',
	[DEVELOPMENT]: 'mapbox://styles/viathdevs/clz0eh5sl01rr01qo6vi0emnk/draft'
}

const mapboxLightStyleUrls = {
	[PRODUCTION]: 'mapbox://styles/viathdevs/clywz78vo00mu01qo4vjs2blw',
	[STAGING]: 'mapbox://styles/viathdevs/clywz78vo00mu01qo4vjs2blw',
	[DEVELOPMENT]: 'mapbox://styles/viathdevs/clywz78vo00mu01qo4vjs2blw/draft'
}

const mapboxDarkStyleUrls = {
	[PRODUCTION]: 'mapbox://styles/viathdevs/clyt6gane005u01paegrkaozd',
	[STAGING]: 'mapbox://styles/viathdevs/clyt6gane005u01paegrkaozd',
	[DEVELOPMENT]: 'mapbox://styles/viathdevs/clyt6gane005u01paegrkaozd/draft'
}

export const SUPPORT_EMAIL = supportEmails[RELEASE_STAGE]
export const SUPPORT_INTERNAL_EMAIL = supportInternalEmails[RELEASE_STAGE]

export const INFO_EMAIL = 'info@maverixbroadband.com'

export const PORTAL_BASE = portalUrls[RELEASE_STAGE] || portalUrls[PRODUCTION]

export const MAPBOX_STYLE_SATELLITE_URL = mapboxSatelliteStyleUrls[RELEASE_STAGE]
export const MAPBOX_STYLE_LIGHT_URL = mapboxLightStyleUrls[RELEASE_STAGE]
export const MAPBOX_STYLE_DARK_URL = mapboxDarkStyleUrls[RELEASE_STAGE]

export const API_BASE = apiUrls[RELEASE_STAGE]
export const API_BASE_LOCAL = import.meta.env.VITE_API_BASE_LOCAL
export const API_ENDPOINT_NON_BUSINESS_CONTACT = '/contact/nonBusiness'
export const API_ENDPOINT_ACCOUNT_INVOICES = '/account/{account-id}/invoices/get'
export const API_ENDPOINT_ACCOUNT_INVOICE = '/account/{account-id}/invoices/{invoice-id}'
export const API_ENDPOINT_ACCOUNT_INVOICES_PAST_DUE = '/account/{account-id}/invoices/past-due?page={page}&limit={limit}'
export const API_ENDPOINT_ACCOUNT_AUTOPAY = '/account/{account-id}/autoPay'
export const API_ENDPOINT_ACCOUNT_PAYMENT_METHODS = '/account/{account-id}/email/{email-id}/payment-methods'
export const API_ENDPOINT_ACCOUNT_AUTOPAY_ENABLE = '/account/{account-id}/autopay/enable'
export const API_ENDPOINT_ACCOUNT_AUTOPAY_REMOVE = '/account/{account-id}/autopay/remove'
export const API_ENDPOINT_ACCOUNT_ONBOARDING = '/account/{account-id}/onboard'
export const API_ENDPOINT_ACCOUNT_PHONE_NUMBERS = '/contact/{contact-id}/phones'
export const API_ENDPOINT_ACCOUNT_PHONE_OPT = '/contact/{contact-id}/phones/{phone-id}/opt'
export const API_ENDPOINT_ACCOUNT_ADD_PHONE_NUMBER = '/contact/{contact-id}/phones/insert'
export const API_ENDPOINT_ACCOUNT_VERIFY_PHONE_NUMBER = '/contact/{contact-id}/phones/verification'
export const API_ENDPOINT_ACCOUNT_EDIT_PHONE_NUMBER = '/contact/{contact-id}/phones/{phone-id}'
export const API_ENDPOINT_PORTAL_ACCOUNTS_NOTES = '/portal/accounts/{account-id}/notes'
export const API_ENDPOINT_PORTAL_ACCOUNTS_NOTES_STICKY = '/portal/accounts/{account-id}/notes/sticky'
export const API_ENDPOINT_PORTAL_NOTES_CATEGORIES = '/portal/notes/categories'
export const API_ENDPOINT_PORTAL_NOTES_UPDATE_CATEOGORY = '/portal/accounts/{account-id}/notes/edit/{note-id}/category'
export const API_ENDPOINT_PORTAL_ACCOUNTS_MACS = '/portal/accounts/{account-id}/macs'
export const API_ENDPOINT_ADDRESS = '/prospect/address'
export const API_ENDPOINT_ALERTS = '/alerts'
export const API_ENDPOINT_ALERTS_LIST = '/alerts/list'
export const API_ENDPOINT_ALERTS_NEW = '/alerts/new'
export const API_ENDPOINT_ALERTS_UPDATE = '/alerts/update/{alert-id}'
export const API_ENDPOINT_ALERTS_DELETE = '/alerts/delete/{alert-id}'
export const API_ENDPOINT_PORTAL_ACCOUNTS_ACTIVATION_DETAILS = '/portal/accounts/{account-id}/activation/details'
export const API_ENDPOINT_PORTAL_SEND_ACTIVATION_REMINDER = '/portal/accounts/{account-id}/activation/sendActivationReminder'
export const API_ENDPOINT_ACCOUNT_ACTIVATION_DETAILS = '/account/{account-id}/activation/details'
export const API_ENDPOINT_PORTAL_SYSTEM_EMAIL_SEND = '/portal/systemEmail/send'
export const API_ENDPOINT_PORTAL_ZONES = '/portal/zones'
export const API_ENDPOINT_PORTAL_ZONE = '/portal/zones/{zone-id}'
export const API_ENDPOINT_PORTAL_ZONES_BILLABLES = '/portal/zones/{zone-id}/billables'
export const API_ENDPOINT_PORTAL_INVOICE_UPDATE = '/portal/accounts/{account-id}/invoices/{invoice-id}'
export const API_ENDPOINT_PORTAL_ACCOUNT_CONTRACT_SERVICES = '/portal/accounts/{account-id}/contract/{contract-id}/services'
export const API_ENDPOINT_PORTAL_OFFLINE_PAY = '/portal/accounts/{account-id}/offlinePay'
export const API_ENDPOINT_PORTAL_GENERATE_NEXT_INVOICE = '/portal/accounts/{account-id}/invoices/generateNext'
export const API_ENDPOINT_PORTAL_INVOICE_REFUND = '/portal/accounts/{account-id}/invoices/{invoice-id}/refund/{invoice-payment-id}'
export const API_ENDPOINT_PORTAL_CREATE_NEW_INVOICE = '/portal/accounts/{account-id}/invoices/new'
export const API_ENDPOINT_ACCOUNT_INVOICE_PAY = '/account/{account-id}/invoices/{invoice-id}/pay'
export const API_ENDPOINT_ACCOUNT_EMAIL_CHANGE_BEGIN = '/auth/email/change/begin'
export const API_ENDPOINT_ACCOUNT_EMAIL_CHANGE_FINALIZE = '/auth/email/change/finalize'
export const API_ENDPOINT_PORTAL_RESET_AUTOPAY = '/portal/accounts/{account-id}/invoices/{invoice-id}/resetAutoPay'
export const API_ENDPOINT_PORTAL_BILLING_TRANSACTIONS = '/portal/accounts/{account-id}/transactions'
export const API_ENDPOINT_PORTAL_ACCOUNT_STATUSES = '/portal/accountStatuses'
export const API_ENDPOINT_ACCOUNT_2FA_STEP_ONE = '/account/{account-id}/2fa/setup/step-one'
export const API_ENDPOINT_ACCOUNT_2FA_STEP_TWO = '/account/{account-id}/2fa/setup/step-two'
export const API_ENDPOINT_ACCOUNT_2FA_DISABLE = '/account/{account-id}/2fa/setup/disable'
export const API_ENDPOINT_AUTH_SEND_TWO_FACTOR = '/auth/sendTwoFactor'
export const API_ENDPOINT_MAINLINE_MAP = '/system/mainline-map'
export const API_ENDPOINT_PORTAL_CREATE_LOGIN = '/portal/accounts/{account-id}/contacts/{contact-id}/createLogin'

export const API_ENDPOINT_PORTAL_PERMISSIONS = '/portal/logins/permission/{permission-name}'

export const API_ENDPOINT_PORTAL_REDISEARCH_QUERY = '/portal/redisearch/query'

export const API_ENDPOINT_PORTAL_BULK_SCHEDULE_EMAIL = '/portal/bulk/schedule'

// external service api keys
export const BUGSNAG_API_KEY = 'feed445de704d896e4455c25ae601ddf'
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBKSU_hEcFsvKNi9yRkfFj-o65oE02BQ4w'
export const MAPBOX_API_KEY = 'pk.eyJ1IjoidmlhdGhkZXZzIiwiYSI6ImNseGV2ejdjNTBta3YycW92ZXNyaGwyZ2EifQ.AAmCdvw5KIHx3jJA18dExA'

export const SHARED_COOKIE_DOMAIN = '.maverixbroadband.com'

export const NOTE_CHARACTER_LIMIT = 1000

export const IN_CONVERSION_ROUTES = [ '/install-status', '/my-details', '/contact' ]

export default {
	PRODUCTION,
	DEVELOPMENT,
	RELEASE_STAGE,
	IS_PRODUCTION,
	COMPANY_NAME,
	COMPANY_PHONE,
	INFO_EMAIL,
	BASE_URL,
	PORTAL_BASE,
	API_BASE,
	API_BASE_LOCAL,
	API_ENDPOINT_NON_BUSINESS_CONTACT,
	API_ENDPOINT_ACCOUNT_INVOICES,
	API_ENDPOINT_ACCOUNT_INVOICE_PAY,
	API_ENDPOINT_ACCOUNT_AUTOPAY,
	API_ENDPOINT_ACCOUNT_PAYMENT_METHODS,
	API_ENDPOINT_ACCOUNT_AUTOPAY_ENABLE,
	API_ENDPOINT_ACCOUNT_AUTOPAY_REMOVE,
	API_ENDPOINT_ACCOUNT_ONBOARDING,
	BUGSNAG_API_KEY,
	GOOGLE_MAPS_API_KEY,
	MAPBOX_API_KEY,
	API_ENDPOINT_ACCOUNT_ADD_PHONE_NUMBER,
	API_ENDPOINT_ACCOUNT_VERIFY_PHONE_NUMBER,
	API_ENDPOINT_ACCOUNT_EDIT_PHONE_NUMBER,
	API_ENDPOINT_PORTAL_ACCOUNTS_NOTES,
	API_ENDPOINT_PORTAL_ACCOUNTS_ACTIVATION_DETAILS,
	API_ENDPOINT_PORTAL_ACCOUNT_STATUSES,
	API_ENDPOINT_PORTAL_SEND_ACTIVATION_REMINDER,
	API_ENDPOINT_ACCOUNT_ACTIVATION_DETAILS,
	API_ENDPOINT_PORTAL_ACCOUNTS_MACS,
	NOTE_CHARACTER_LIMIT,
	API_ENDPOINT_PORTAL_PERMISSIONS,
	API_ENDPOINT_PORTAL_ZONES,
	API_ENDPOINT_PORTAL_ZONES_BILLABLES,
	API_ENDPOINT_PORTAL_INVOICE_REFUND,
	MAPBOX_STYLE_SATELLITE_URL,
	MAPBOX_STYLE_LIGHT_URL,
	MAPBOX_STYLE_DARK_URL,
	API_ENDPOINT_PORTAL_REDISEARCH_QUERY,
	API_ENDPOINT_PORTAL_BULK_SCHEDULE_EMAIL,
	API_ENDPOINT_PORTAL_RESET_AUTOPAY
}
