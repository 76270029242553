import { defineNuxtPlugin } from '#app'
import { nextTick } from 'vue'
import type { DirectiveBinding, ObjectDirective } from 'vue'
import type { TooltipOptions, TooltipBinding } from '@/types/plugins'

// Extend HTMLElement to include a _tooltipCleanup property
interface TargetElement extends HTMLElement {
	_tooltipCleanup?: () => void
}

const DEFAULT_CLASSES = 'bg-mx-gray-50 text-black dark:text-mx-gray-300 dark:bg-mx-green-900 p-2 rounded-md shadow-lg text-sm'
const VISIBILITY_CLASSES = 'opacity-0 transition-opacity'

// Default offsets in pixels
const DEFAULT_OFFSET = {
	x: 20,
	y: 4
}

const DEFAULT_DELAY = {
	show: 0,
	hide: 0
}

export default defineNuxtPlugin((nuxtApp) => {
	const vTooltip: ObjectDirective = {
		async beforeMount (el: TargetElement, binding: DirectiveBinding<TooltipBinding>) {
			await nextTick()

			// 1) Figure out if the binding is just a string or a full object
			const raw = binding.value
			if (!raw) { return }

			let options: TooltipOptions
			if (typeof raw === 'string') {
				// If it's a string, treat it as the tooltip text
				options = {
					content: raw
				}
			} else {
				// It's already a TooltipOptions object
				options = raw
			}

			// 2) Merge in defaults
			const offset = { ...DEFAULT_OFFSET, ...options.offset }
			const delay = { ...DEFAULT_DELAY, ...options.delay }
			const trigger = options.trigger || 'hover'

			// 3) Create the tooltip DOM
			const tooltipEl = document.createElement('div')
			tooltipEl.setAttribute('role', 'tooltip')
			tooltipEl.setAttribute('aria-hidden', 'true')

			el.style.cursor = 'pointer'

			if (typeof options.content === 'string') {
				tooltipEl.innerText = options.content
			} else if (options.content instanceof HTMLElement) {
				tooltipEl.appendChild(options.content)
			} else {
				return
			}

			// 4) Combine classes
			const providedClasses = options.classes || ''
			const shouldOverrideStyles = options.overrideStyles && providedClasses.length
			const customClasses = `${VISIBILITY_CLASSES} ${providedClasses}`
			const defaultClasses = `${VISIBILITY_CLASSES} ${DEFAULT_CLASSES}`
			const tooltipClasses = shouldOverrideStyles
				? customClasses
				: `${defaultClasses} ${providedClasses}`
			tooltipEl.className = tooltipClasses

			Object.assign(tooltipEl.style, {
				position: 'absolute',
				whiteSpace: 'normal',
				pointerEvents: 'auto',
				top: '0',
				left: '0',
				zIndex: '9999'
			})

			document.body.appendChild(tooltipEl)

			// 5) Position function
			const updateTooltipPosition = () => {
				const rect = el.getBoundingClientRect()

				tooltipEl.style.top = `${rect.bottom + window.scrollY + offset.y}px`
				tooltipEl.style.left = `${rect.left + window.scrollX + offset.x}px`
			}

			// 6) Show & hide
			const showTooltip = () => {
				setTimeout(() => {
					tooltipEl.classList.remove('opacity-0')
					tooltipEl.classList.add('opacity-100')
				}, delay.show)
			}
			const hideTooltip = () => {
				setTimeout(() => {
					tooltipEl.classList.remove('opacity-100')
					tooltipEl.classList.add('opacity-0')
				}, delay.hide)
			}
			const toggleTooltip = () => {
				if (tooltipEl.classList.contains('opacity-0')) {
					showTooltip()
				} else {
					hideTooltip()
				}
			}

			// 7) Immediate positioning, plus scroll updates
			updateTooltipPosition()

			window.addEventListener('scroll', updateTooltipPosition, true)

			// Get position on mouse enter in case the element moves (e.g., slides in)
			window.addEventListener('mouseenter', updateTooltipPosition, true)

			// Handle transitions/mutations (e.g., if the element slides in)
			const observer = new MutationObserver(() => {
				requestAnimationFrame(() => updateTooltipPosition())
			})
			observer.observe(el, { attributes: true })

			// Hide if clicked outside
			const onDocumentClick = (e: MouseEvent) => {
				if (!tooltipEl.contains(e.target as Node) && !el.contains(e.target as Node)) {
					hideTooltip()
				}
			}
			document.addEventListener('click', onDocumentClick, true)

			// 8) Attach trigger listeners
			if (trigger === 'click') {
				el.addEventListener('click', toggleTooltip)
			} else {
				el.addEventListener('mouseenter', showTooltip)
				el.addEventListener('mouseleave', hideTooltip)
			}

			// 9) Cleanup
			el._tooltipCleanup = () => {
				window.removeEventListener('scroll', updateTooltipPosition, true)
				document.removeEventListener('click', onDocumentClick, true)
				observer.disconnect()

				if (trigger === 'click') {
					el.removeEventListener('click', toggleTooltip)
				} else {
					el.removeEventListener('mouseenter', showTooltip)
					el.removeEventListener('mouseleave', hideTooltip)
				}

				el.removeEventListener('mouseenter', updateTooltipPosition, true)

				document.body.removeChild(tooltipEl)
			}
		},

		beforeUnmount (el: TargetElement) {
			el._tooltipCleanup?.()
		}
	}

	nuxtApp.vueApp.directive('tooltip', vTooltip)
})
