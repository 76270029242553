import { API_BASE } from '@/constants/index.js'
import { ref, computed } from 'vue'
import type { AuthResponse, UserData, MappedAccount, MappedAddress } from '@/types/auth'
import type { AccountStatus, InConversionStatus } from '@/types/account-statuses'
import { mapAuthResponse } from '@/utils/mappers/AuthMap'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { IN_CONVERSION_STATUSES } from '@/constants/accounts/statuses'
import { ALL_SYSTEM_SETTINGS_PERMISSIONS } from '@/constants/systemSettings'

const { bugsnagReport } = useErrorReporter()

const loading = ref(true)

const userData: Ref<UserData | null> = ref(null)
const selectedAccount: Ref<MappedAccount | null> = ref(null)

// Computed properties for commonly used data
const emailId = computed(() => userData.value?.emailId?.toString() || '')
const siteAdmin = computed(() => userData.value?.siteAdmin || false)
const accountId = computed(() => selectedAccount?.value?.accountId?.toString() || '')
const contactId = computed(() => userData.value?.contact.contactId?.toString() || '')
const firstName = computed(() => userData.value?.contact?.firstName || '')
const lastName = computed(() => userData.value?.contact?.lastName || '')
const email = computed(() => userData.value?.contact?.email || '')
const phone = computed(() => userData.value?.contact?.phone || '')
const fullName = computed(() => `${firstName.value} ${lastName.value}` || '')

const hasMultipleAccounts = computed(() => userData?.value?.accounts?.length && userData?.value?.accounts?.length > 1)
const accounts = computed(() => userData?.value?.accounts || [])

const role = computed(() => { // TODO: update this to use the roles from the user data as well as the middleware auth.global.js
	// return userData.value?.roles?.[0] || 'external'
	return emailId.value ? siteAdmin.value ? 'internal' : 'external' : ''
})
const customerHasNoAccounts = computed(() => !accounts.value?.length && role.value === 'external')

// START: Permissions ---------------------------------------------------------
const permissions = computed(() => userData.value?.permissions || [])
const isViewAdmin = computed(() => permissions.value?.includes('zones.edit'))
const isDelinquentToggleAdmin = computed(() => permissions.value?.includes('delinquent.toggle'))
const isDelinquentWebhookAdmin = computed(() => permissions.value?.includes('delinquent.webhook'))
const hasSomeSystemSettingsPermission = computed(() => permissions.value?.some(permission => ALL_SYSTEM_SETTINGS_PERMISSIONS.includes(permission)))
const hasSettingsEditPermission = computed(() => permissions.value?.includes('settings.edit'))
const hasSettingsViewPermission = computed(() => permissions.value?.includes('settings.view'))
const hasSettingsDeletePermission = computed(() => permissions.value?.includes('settings.delete'))
const hasSettingsCreatePermission = computed(() => permissions.value?.includes('settings.create'))
const hasAlertsViewAllPermission = computed(() => permissions.value?.includes('alerts.viewAll'))
const hasAlertsEditPermission = computed(() => permissions.value?.includes('alerts.edit'))
const hasAccountsExportPermission = computed(() => permissions.value?.includes('accounts.export'))
// END: Permissions -----------------------------------------------------------

const streetAddress = computed(() => userData.value?.contact?.address?.streetAddress || '')
const city = computed(() => userData.value?.contact?.address?.city || '')
const state = computed(() => userData.value?.contact?.address?.state || '')
const zip = computed(() => userData.value?.contact?.address?.zip || '')
const country = computed(() => userData.value?.contact?.address?.country || '')
const fullAddress = computed(() => {
	const address = userData.value?.contact?.address
	return address ? `${streetAddress.value}, ${city.value}, ${state.value}, ${country.value}` : ''
})
const coordinates = computed(() => userData?.value?.contact?.address?.coordinates || { lat: 0, lng: 0 })
const isSurveyed = computed(() => selectedAccount?.value?.serviceDetails?.surveyed)
const prospectInterestBillablesSnapshot = computed(() => selectedAccount?.value?.prospectInterestBillablesSnapshot)
const zone = computed(() => selectedAccount?.value?.zone)

// START: Account Status and Billing -----------------------
const hasInvoices = computed(() => selectedAccount?.value?.hasInvoices)
const billingActive = computed(() => selectedAccount?.value?.billingActive)
const isActive = computed(() => selectedAccount?.value?.accountStatus === 'Active')
const accountStatus = computed(() => selectedAccount?.value?.accountStatus || 'Inactive')
const inConversion = computed(() => isInConversionStatus(accountStatus.value))
const isInConversionStatus = (status: AccountStatus): status is InConversionStatus => {
	return IN_CONVERSION_STATUSES.includes(status as InConversionStatus)
}
// Show billing in navigation if there are invoices, or if the account is not in conversion status but is in active status or billing is active
const showBilling = computed(() => hasInvoices.value || (!inConversion.value && (isActive.value || billingActive.value)))
// END: Account Status and Billing -------------------------

const mostRecentCompletedStep = computed(() => {
	const stepsLength = selectedAccount?.value?.prospectConversionSteps?.length || 1
	return selectedAccount?.value?.prospectConversionSteps?.[stepsLength - 1]?.step || ''
})

const formatAddress = (address: MappedAddress | undefined) => {
	if (!address) { return 'No Address' }
	return `${address.streetAddress}, ${address.city}, ${address.stateIso}, ${address.zip}`
}

const setSelectedAccount = (accountId: number) => {
	sessionStorage.setItem('selectedAccount', accountId?.toString())
	selectedAccount.value = userData.value?.accounts?.find(account => account?.accountId === accountId) || null
}

const accountStreetAddress = computed(() => {
	return selectedAccount.value?.address?.streetAddress || ''
})
const accountCity = computed(() => {
	return selectedAccount.value?.address?.city || ''
})
const accountState = computed(() => {
	return selectedAccount.value?.address?.state || ''
})
const accountZip = computed(() => {
	return selectedAccount.value?.address?.zip || ''
})
const accountCountry = computed(() => {
	return selectedAccount.value?.address?.country || ''
})
// currently BE not returning coordinates for account address
const accountCoordinates = computed(() => {
	return selectedAccount.value?.address?.coordinates || { lat: 0, lng: 0 }
})

const fullAccountAddress = computed(() => {
	const address = selectedAccount.value?.address
	return address ? `${accountStreetAddress.value}, ${accountCity.value}, ${accountState.value}, ${accountCountry.value}` : ''
})

const twoFactorEnabled = computed(() => !!userData.value?.twoFactorEnabled)

export function useAuth () {
	// Utility function to clear authentication data (for when user logs out but still has a portal window open)
	const clearAuth = () => {
		userData.value = null
	}

	const refetchAuth = async () => {
		await getAuth(true)
	}

	async function getAuth (refetch?: boolean) {
		if (!userData.value || refetch) {
			loading.value = true
			await useFetch<AuthResponse>(`${API_BASE}/auth`, {
				credentials: 'include',
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						userData.value = mapAuthResponse(response._data)
						if (!hasMultipleAccounts.value) {
							selectedAccount.value = userData.value?.accounts?.[0] || null
						}
						const selectedAccountIdInSessionStorage = sessionStorage?.getItem('selectedAccount')
						if (userData.value?.accounts?.find(account => account?.accountId === Number(selectedAccountIdInSessionStorage))) {
							setSelectedAccount(Number(selectedAccountIdInSessionStorage))
						}
					} else {
						bugsnagReport({
							error: new Error('getAuth: no data'),
							showToast: true
						})
					}
				}
			})
			loading.value = false
		}
	}

	return {
		loading,
		refetchAuth,
		getAuth,
		clearAuth,
		userData,
		emailId,
		siteAdmin,
		accountId,
		contactId,
		firstName,
		lastName,
		fullAddress,
		fullName,
		role,
		customerHasNoAccounts,
		permissions,
		hasSomeSystemSettingsPermission,
		hasSettingsEditPermission,
		hasSettingsViewPermission,
		hasSettingsDeletePermission,
		hasSettingsCreatePermission,
		hasAccountsExportPermission,
		isViewAdmin,
		isDelinquentToggleAdmin,
		isDelinquentWebhookAdmin,
		email,
		phone,
		streetAddress,
		city,
		state,
		zip,
		isActive,
		coordinates,
		billingActive,
		isSurveyed,
		accountStatus,
		prospectInterestBillablesSnapshot,
		mostRecentCompletedStep,
		inConversion,
		showBilling,
		zone,
		hasMultipleAccounts,
		accounts,
		selectedAccount,
		formatAddress,
		setSelectedAccount,
		accountStreetAddress,
		accountCity,
		accountState,
		accountZip,
		accountCoordinates,
		fullAccountAddress,
		twoFactorEnabled,
		hasAlertsEditPermission,
		hasAlertsViewAllPermission
	}
}
